import React, { useState, useEffect } from 'react';

import {
    Link,
    useI18next,
    I18nextContext
} from "gatsby-plugin-react-i18next"

import { AnchorLink } from "gatsby-plugin-anchor-links";

import styled from "styled-components"
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Divider from '@material-ui/core/Divider';

import QuestionCard from './question-card';


const TOTAL_QUESTIONS = 10

const questionsArray = [
    {
        question: `Our own cognitive biases lead us in random directions`,
        answers: ['true', 'false'],
        correctAnswer: 'false',
        explanation: `Our cognitive biases are unseen but “built in”. They give us similar “guidance” in similar situations. This results in a pattern of decision making errors that keeps repeating itself.`
    },
    {
        question: `Those with deep understanding of a problem tend to underestimate their own knowledge`,
        answers: ['true', 'false'],
        correctAnswer: 'true',
        explanation: `In addition, those with limited understanding of the same problem tend to overestimate their knowledge. This makes it difficult for experts to convince others, because they cannot see how difficult it might be for others to understand what they are saying. And those “others” probably believe they already know the right answers.`
    },
    {
        question: `You can avoid “confirmation bias” by putting your trust in facts over stories`,
        answers: ['true', 'false'],
        correctAnswer: 'false',
        explanation: `Even when we believe we are making a decision based on facts alone, we would normally create a story around them for ourselves. We cannot consider objective facts without finding, consciously or not, a story that makes sense of them.`
    },
    {
        question: `People tend to accept that their core beliefs are wrong only when shown clear facts proving this`,
        answers: ['true', 'false'],
        correctAnswer: 'false',
        explanation: `When our core beliefs are challenged we tend to push back and believe in them even stronger. Even when shown facts to the contrary. We tend to take questioning our core beliefs as an attack on our personality or group identity. And we will instinctively fight such “attacks”.`
    },
    {
        question: `Companies usually do not take enough risks`,
        answers: ['true', 'false'],
        correctAnswer: 'true',
        explanation: `Loss aversion, uncertainty aversion and hindsight bias are just three of the common cognitive biases that contribute to this. Of course, this may not be obvious reading the media. Other factors, such as the survivor bias, may focus attention on those few companies that took a successful risk.`
    },
    {
        question: `Most people will “buy when they are happy and sell when they are not”`,
        answers: ['true', 'false'],
        correctAnswer: 'true',
        explanation: `Focusing or thinking about satisfying events will make us more optimistic about other things as well. The opposite is also true. Hence, when we are happy we are more likely to make positive (“buy”) decisions and when we are unhappy we tend to favour negative (“sell”) decisions.`
    },
    {
        question: `Working as a group always leads to a better outcome`,
        answers: ['true', 'false'],
        correctAnswer: 'false',
        explanation: `While groups can be very effective, groupthink can lead the best informed participants to keep their perspectives to themselves. Therefore, a group setting may result in not bringing out the highest quality input to make the best decision.`
    },
    {
        question: `There are only 14 key cognitive biases that have significant impact on business decisions`,
        answers: ['true', 'false'],
        correctAnswer: 'false',
        explanation: `The number of known biases is above 150. About 100 are usually listed as “important” and 20 to 25 have been shown to have a significant, recurring impact on everyday business decisions.`
    },
    {
        question: `The best way to deal with our individual biases is actually NOT trying to overcome them`,
        answers: ['true', 'false'],
        correctAnswer: 'true',
        explanation: `Contrary to much of the popular press on the topic, most of us are unable to overcome our biases in a lasting way. Luckily, we don’t need to. We can set up decision making frameworks that generate the highest quality decisions at the organizational level, even if individual biases remain unchanged.`
    },
    {
        question: `In fast changing environments predictive models (AI, Big Data Analytics) are the best tools for gaining competitive advantage`,
        answers: ['true', 'false'],
        correctAnswer: 'false',
        explanation: `Even with the best machine learning algorithms, predictive modelling still relies on already existing pools of data to make correlation based forecasts. If the environment changes fast, the “current” pool of data can quickly become obsolete. Until it is replenished with new data it can mis-guide businesses. On the other hand, humans’ cognitive processes change extremely slowly in response to environmental factors. Therefore, using cognitive analysis techniques will provide a steady, valid direction, even in fast changing environments. This makes it the best way to gain competitive advantage.`
    },
]

const questionsArrayHU = [
    {
        question: `A kognitív torzítások véletlenszerűen befolyásolják a döntéseinket. `,
        answers: ['igaz', 'hamis'],
        correctAnswer: 'hamis',
        explanation: `A kognitív torzítások folyamatosan jelen vannak a gondolkodásunkban. Azonos helyzetben azonosan működnek, azaz előrejelezhető módon, egy “mintázatot” követnek a torzításból eredő döntési hibák.`
    },
    {
        question: `Azok, akik egy adott témában mély ismeretekkel rendelkeznek, hajlamosak alulértékelni a tudásukat.`,
        answers: ['igaz', 'hamis'],
        correctAnswer: 'igaz',
        explanation: `Ezzel egyidejűleg pedig a korlátozott tudással rendelkezők jellemzően túlértékelik a tudásukat. A szakértők általában pont azért tudnak olyan nehezen meggyőzni másokat, mert alulbecsülik, hogy mennyire nehéz követni az érvelésüket. Ráadásul, akiket meg akarnak győzni, úgy gondolják, hogy már tudják a helyes választ.`
    },
    {
        question: `Azzal, hogy story helyett a tényekre koncentrálunk elkerülhető a “megerősítési torzítás” (confirmation bias).`,
        answers: ['igaz', 'hamis'],
        correctAnswer: 'hamis',
        explanation: `Még abban az esetben is, amikor meg vagyunk győződve arról, hogy tények alapján döntünk, valójában egy olyan keretbe foglaljuk a tényeket, ami alátámasztja a storyt. A tényeket önmagukban nehezen tudjuk értelmezni, óhatatlanul egy story-ba foglaljuk, és azon keresztül értelmezzük őket.`
    },
    {
        question: `Megfelelő tények ismeretében belátjuk, hogy az alapfeltevéseink hibásak. `,
        answers: ['igaz', 'hamis'],
        correctAnswer: 'hamis',
        explanation: `Ha megkérdőjelezik az alapfeltevéseinket, akkor inkább az azoknak ellentmondó tényeket utasítjuk el és még jobban ragaszkodunk az eredeti álláspontunkhoz. Az alapfeltevéseink  megkérdőjelezését sokszor személyes támadásként fogjuk fel, ösztönösen védekezünk és visszatámadunk.`
    },
    {
        question: `A vállalatok általában túlságosan kockázatkerülők.`,
        answers: ['igaz', 'hamis'],
        correctAnswer: 'igaz',
        explanation: `A veszteség minimalizálás (loss aversion), a bizonytalanság elkerülése (uncertainty aversion) és az utólagos magyarázat (hindsight bias) a három leggyakoribb torzítás, amelyek a kockázatkerülő magatartást okozzák. Persze ezzel ritkán találkozik az ember a sajtóban. Ezzel szemben a túlélési torzítás (survivor bias) eredményeképpen az elért vállalati sikerek gyakrabban kerülnek az üzleti magazinok lapjaira.`
    },
    {
        question: `Az emberek általában vásárolnak, ha boldogok, és eladnak, ha nem. `,
        answers: ['igaz', 'hamis'],
        correctAnswer: 'igaz',
        explanation: `Ha pozitív dolgokat élünk meg, optimistábban tekintünk az egész világra, és fordítva. Ezért van, hogy ha boldogok vagyunk, akkor nagyobb eséllyel hozunk pozitív döntéseket (“vásárolunk”), és ha boldogtalanok vagyunk a döntéseink is negatívabbá válnak (“eladunk”).`
    },
    {
        question: `A csoportmunka mindig jobb döntéshez vezet.`,
        answers: ['igaz', 'hamis'],
        correctAnswer: 'hamis',
        explanation: `Bár a csoportmunka sok esetben valóban hatékony, azonban a csoport dinamika miatt gyakran a legkompetensebb ember szava veszik el a vitákban, ezért nem jó döntés születik.`
    },
    {
        question: `Összesen 14, az üzleti döntésekre jelentős hatással bíró torzítást ismerünk.`,
        answers: ['igaz', 'hamis'],
        correctAnswer: 'hamis',
        explanation: `Több, mint 150 torzításról azonosítottak, amelyek közül mintegy 100-at szoktak fontosként említeni és ezek közül 20-25 a kritikus, amelyek újra és újra megjelennek az üzleti döntésekben.`
    },
    {
        question: `A legjobb, ha NEM próbáljuk meg magunk “kikapcsolni” az egyéni torzításainkat.`,
        answers: ['igaz', 'hamis'],
        correctAnswer: 'igaz',
        explanation: `Szemben azzal, amit gyakran lehet olvasni, a legtöbb ember nem képes tartósan “kikapcsolni” a torzításait. A jó hír az, hogy nem is kell, mert a megfelelő döntési struktúra kialakításával szervezeti szinten kiiktathatók az egyéni hibák.`
    },
    {
        question: `A gyorsan változó környezetben az AI, Big Data alapú prediktív modellek jelentik a tartós versenyelőny alapját. `,
        answers: ['igaz', 'hamis'],
        correctAnswer: 'hamis',
        explanation: `Ezek a modellek mind meglévő, múltbéli adatokra támaszkodnak, amelyek gyors környezeti változások esetén hamar elavulttá válnak, és ily módon fals eredményekre vezetnek. Ezzel szemben az emberi gondolkodás nagyon lassan változik, ezért ennek megértése, a kognitív torzítások figyelembe vétele sokkal stabilabb, jobb döntéseket eredményez.`
    },
]


const Instructions = styled.div`
    && {
        margin-bottom: 40px;
        opacity: 1;
        // background: #2c466b;
        padding: 10px 20px;
        max-height: 105px;
        
          @media screen and (max-width: 1220px) {
              max-height: initial;
              width: 100%;
          }
        
        p {
            font-size: 20px;
            // color: white;
            text-align: center;
        }
        
    }

    
    &.hidden {
        opacity: 0;
        max-height: 0;
        margin: 0;
        padding: 0;
        visibility: hidden;
        transition: all 1s ease;
    }
`
const YourScore = styled.p`
    && {
        margin-top: 24px;
        font-size: 42px;
        text-align: center;
        
          @media screen and (max-width: 1220px) {
              margin-top: 0;
              font-size:24px;
          }
    }
`

const MoreInformation = styled.div`
    a {
        color: #ff8000;
    }
`

const ResetButtonWrapper = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;

    &::after {
        content: attr(data-reset);
        position: absolute;
        top: -18px;
        left: -6px;
        visibility: hidden;
        transition: all 0.1s ease;
        opacity: 0;

    }

    &.hu::after {
        left: -19x;
    }

    &:hover {
        &::after {
            visibility: visible;
            opacity: 1;
        }
    }
`

const Quiz = () => {

    const context = React.useContext(I18nextContext);
    const language = context.language;

    const { t } = useI18next();

    const [questions, setQuestions] = useState((language === "hu") ? questionsArrayHU : questionsArray);
    const [number, setNumber] = useState(0);
    const [userAnswers, setUserAnswers] = useState([]);
    const [score, setScore] = useState(0);
    const [gameOver, setGameOver] = useState(true);

    // useEffect(()=>{

    // }, [])


    function startGame() {
        setGameOver(false);
    }

    function checkAnswer(e) {
        const userAnswer = e.currentTarget.value;
        const correctAnswer = questions[number].correctAnswer.toString();

        const isCorrect = correctAnswer === userAnswer;

        if (isCorrect) setScore((prev) => prev + 1);

        const answerObject = {
            question: questions[number].question,
            answers: questions[number].answers,
            correctAnswer: questions[number].correctAnswer,
            explanation: questions[number].explanation,
            userAnswer,
            isCorrect,
        };

        setUserAnswers((prev) => [...prev, answerObject]);

        // const nextQ = number + 1;
        // if (nextQ === TOTAL_QUESTIONS) {
        //   setGameOver(true);
        //   console.log("Game over")
        // } 
    }

    function nextQuestion() {
        const nextQ = number + 1;
        if (nextQ === TOTAL_QUESTIONS) {
            setGameOver(true);
        } else {
            setNumber(nextQ);
        }
    }

    function reset() {
        setNumber(0)
        setUserAnswers([])
        setScore(0)
        setGameOver(false)


    }

    return (
        <div style={{ position: 'relative' }}>
            { gameOver && (number == 0) ? (
                <Box>
                    <Instructions className={userAnswers[number] ? "hidden" : null}>
                        <p>{t("TEST-YOURSELF.ARE-YOU-BIASED-PREAMBLE")}</p>
                        <p>{t("TEST-YOURSELF.ARE-YOU-BIASED-PREAMBLE-LINE2", { numberOfQuestions: questions.length })}</p>
                    </Instructions>
                    <Box mt={5}>
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<NavigateNextIcon />}
                            className='next'
                            onClick={startGame}
                        >
                            {t("TEST-YOURSELF.START-THE-QUIZ")}
                        </Button>
                    </Box>
                </Box>
            ) : null}

            {
                !gameOver ? (
                    <h3>{t("TEST-YOURSELF.QUESTION")}: {number + 1} / {questions.length}</h3>
                ) : null
            }

            {
                !gameOver ? (
                    <QuestionCard
                        questionNumber={number + 1}
                        totalQuestions={TOTAL_QUESTIONS}
                        question={questions[number].question}
                        answers={questions[number].answers}
                        userAnswer={userAnswers ? userAnswers[number] : undefined}
                        explanation={questions[number].explanation}
                        callBack={checkAnswer}
                        correctAnswer={questions[number].correctAnswer}
                    />) : null
            }



            {!gameOver && userAnswers.length === number + 1 && number <= TOTAL_QUESTIONS - 1 ? (
                <Box mt={5}>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<NavigateNextIcon />}
                        className='next'
                        onClick={nextQuestion}
                    >
                        {number + 1 < TOTAL_QUESTIONS ? t("TEST-YOURSELF.NEXT-QUESTION") : t("TEST-YOURSELF.GET-RESULT")}
                    </Button>
                </Box>
            ) : null}

            { gameOver && userAnswers.length > 0 && (
                <div>
                    <div>
                        <YourScore>{t("TEST-YOURSELF.YOUR-SCORE")}: {score} / {questions.length}</YourScore>
                    </div>
                    <Divider />
                    {
                        (language === "en") &&
                        <MoreInformation>
                            <p>Ask CodeBreakers how to recognize and filter out the harmful effects of cognitive biases at your workplace.  <Link to="/contact">Contact us</Link> for details.</p>
                            <p>If you would like to learn more on the topic, {/*see our <Link to="/publications/articles-white-papers">White Papers</Link>, or*/} read the following books:</p>
                            <p><b><AnchorLink to="/publications/books#youre-about-to-make-a-terrible-mistake">“YOU ARE ABOUT TO MAKE A TERRIBLE MISTAKE!”</AnchorLink></b> - by Olivier Sibony</p>
                            <p><b><AnchorLink to="/publications/books#the-art-of-thinking-clearly">“THE ART OF THINKING CLEARLY”</AnchorLink></b> - by Rolf Dobelli</p>
                        </MoreInformation>
                    }

                    {
                        (language === "hu") &&
                        <MoreInformation>
                            <p>A CodeBreakers csapat segít a kognitív torzítások kiszűrésében. További információkért lépjen kapcsolatba velünk a <Link to="/contact">Kapcsolat</Link> menüben található elérhetőségeinken. </p>
                            <p>Ha többet szeretne tudni a témáról, az alábbi két könyvet ajánljuk figyelmébe: </p>
                            <p><b><AnchorLink to="/hu/publications/books#youre-about-to-make-a-terrible-mistake">“YOU ARE ABOUT TO MAKE A TERRIBLE MISTAKE!”</AnchorLink></b> (Olivier Sibony)</p>
                            <p><b><AnchorLink to="/hu/publications/books#the-art-of-thinking-clearly">“THE ART OF THINKING CLEARLY”</AnchorLink></b> (Rolf Dobelli)</p>
                        </MoreInformation>
                    }


                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<RotateLeftIcon />}
                        className='next'
                        onClick={reset}
                    >
                        {t("TEST-YOURSELF.RESET-QUIZ")}
                    </Button>
                </div>
            )}
            {
                !gameOver &&
                <ResetButtonWrapper data-reset={t("TEST-YOURSELF.RESET")} className={(language === "hu") && "hu"}>
                    <RotateLeftIcon onClick={reset} />
                </ResetButtonWrapper>
            }

        </div>
    )
}

export default Quiz