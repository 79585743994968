import React from "react"
import {
  useI18next,
} from "gatsby-plugin-react-i18next"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AreYouBiasedContent from "../../components/are-you-biased-content"



const AreYouBiasedPage = () => {
  const {t} = useI18next();

  return (
    <Layout>
      <SEO title={t("MENU.ARE-YOU-BIASED")} />
      <AreYouBiasedContent />
    </Layout>
  )
}

export default AreYouBiasedPage